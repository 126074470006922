import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import {createPinia, PiniaPluginContext} from "pinia";
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

const installPersistedStatePlugin = createPersistedStatePlugin();

const pinia = createPinia();
pinia.use((context: PiniaPluginContext) => installPersistedStatePlugin(context))

const app = createApp(App)
  .use(IonicVue, {
      mode: 'ios',                    // https://ionicframework.com/docs/vue/config
      swipeBackEnabled: false         // https://stackoverflow.com/questions/66567255/prevent-swipe-back-on-ios-ionic-5-vue-app, https://github.com/ionic-team/ionic-framework/pull/22568
  })
  .use(router)
  .use(pinia);
  
router.isReady().then(() => {
  app.mount('#app');
});