import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/meineEFN'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/meineEFN'
      },
      // Meine EFN
      {
        path: 'meineEFN',
        component: () => import('@/views/MeineEFNPage.vue')
      },
      // Fobi-Suche
      {
        path: 'fobiSuche',
        component: () => import('@/views/FobiSuchePage.vue')
      },
      {
        path: 'fobiSucheList',
        component: () => import('@/views/FobiSucheListPage.vue')
      },
      {
        path: 'fobiSucheDetails/:vnr',
        component: () => import('@/views/FobiSucheDetailsPage.vue')
      },
      // Punktekonto
      {
        path: 'punktekonto',
        component: () => import('@/views/PunkteKontoPage.vue')
      },
      // Hilfe
      {
        path: 'infoHilfe',
        component: () => import('@/views/InfoHilfePage.vue')
      },
      {
        path: 'infoHilfe/hilfe',
        component: () => import('@/views/infoHilfe/HilfePage.vue')
      },
      {
        path: 'infoHilfe/konzeption',
        component: () => import('@/views/infoHilfe/KonzeptionPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(/*to, from, savedPosition*/) {
    return {top: 0}                                         // TODO not working ?!
  }
})

export default router
